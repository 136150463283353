import styled from "styled-components/macro";
import PasswordGeneratorOptions from "../components/PasswordGeneratorOptions";
import {useState} from "react";
import PasswordGenerate from "../components/PasswordGenerate";
import {useNavigate} from "react-router-dom";



export default function PasswordGenerator() {

    const [passwordOptions, setPasswordOptions] = useState({
        length: 16,
        uppercase: true,
        lowercase: true,
        numbers: true,
        symbols: true,
    });

    return (
        <StyledMainContainer>
            <PasswordGeneratorOptions passwordOptions={passwordOptions} setPasswordOptions={setPasswordOptions}/>
            <PasswordGenerate passwordOptions={passwordOptions} />
        </StyledMainContainer>
    )
}

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledHeadline = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 30px;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

