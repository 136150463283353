import {useState} from "react";
import SetCredentials from "../components/SetCredentials";
import RespondedCredentials from "../components/RespondedCredentials";
import passTransferLogo from "../assets/passTransferLogo2.png";
import styled from "styled-components/macro";

export default function CreateEntry() {

    const [response, setResponse] = useState(null);

    return (
        <>
            {response?.id == null ?
                <SetCredentials response={response} setResponse={setResponse}/>
                : <RespondedCredentials response={response} setResponse={setResponse}/>
            }
        </>
    )
}