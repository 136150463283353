import axios from 'axios'

const createNewEntry = (credentials) => {
    return axios.post('/api/v1/password/newentry', credentials)
        .then(res => res);
}

const getEntry = (credentials) => {
    return axios.get('/api/v1/password/getentry', {params: credentials})
        .then(res => res);
}

export {createNewEntry, getEntry}