import styled from "styled-components/macro";
import '././css/PasswordGeneratorOptions.css';

export default function PasswordGeneratorOptions({passwordOptions, setPasswordOptions}) {

    const handleChange = (e) => {
        switch (e.target.id) {
            case 'length':
                setPasswordOptions({...passwordOptions, [e.target.id]: e.target.value});
                break;
            default:
                setPasswordOptions({...passwordOptions, [e.target.id]: e.target.value === "true" ? false : true});

        }

    }

    return (
        <StyledContainer>
            <StyledOptionContainer>
                <StyledParagraph>Uppercase</StyledParagraph>
                <StyledCheckbox type="checkbox" id="uppercase" className="checkbox" defaultChecked={passwordOptions?.uppercase} value={passwordOptions?.uppercase}
                                onChange={handleChange}/>
            </StyledOptionContainer>
            <StyledOptionContainer>
                <StyledParagraph>Lowercase</StyledParagraph>
                <StyledCheckbox type="checkbox" id="lowercase" className="checkbox" defaultChecked={passwordOptions?.lowercase} value={passwordOptions?.lowercase}
                                onChange={handleChange}/>
            </StyledOptionContainer>
            <StyledOptionContainer>
                <StyledParagraph>Numbers</StyledParagraph>
                <StyledCheckbox type="checkbox" id="numbers" className="checkbox" defaultChecked={passwordOptions?.numbers} value={passwordOptions?.numbers}
                                onChange={handleChange}/>
            </StyledOptionContainer>
            <StyledOptionContainer>
                <StyledParagraph>Symbols</StyledParagraph>
                <StyledCheckbox type="checkbox" id="symbols" className="checkbox" defaultChecked={passwordOptions?.symbols} value={passwordOptions?.symbols}
                                onChange={handleChange}/>
            </StyledOptionContainer>
            <StyledOptionContainer>
                <StyledParagraph>Length</StyledParagraph>
                <StyledSliderContainer>
                    <StyledSlider type="range" id="length" className="slider" value={passwordOptions?.length} min="10" max="30" name="length"
                           onInput={handleChange}/>
                    <StyledParagraph>{passwordOptions?.length}</StyledParagraph>
                </StyledSliderContainer>
            </StyledOptionContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`

const StyledSlider = styled.input`
      &::-webkit-slider-thumb {
    background-color: #5a665b;
  }
`

const StyledSliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
      &::-webkit-slider-thumb {
    accent-color: currentcolor;
  }
  accent-color: currentcolor;
`

const StyledOptionContainer = styled.div`
    display: grid;
    width: 80%;
    grid-template-columns: 100px 1fr;
    .checkbox:checked {
        accent-color: currentcolor;
    }
    .slider {
        color: #5a665b; !important
    }
`

const StyledCheckbox = styled.input`
    width: 20px;
    height: 20px;
    border: none;
    padding: 15px;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    align-self: center;
    justify-self: left;
  &:checked {
    color: #5a665b; !important
  }
`

const StyledParagraph = styled.p`
    margin: 10px 0 10px 0;
`