import styled from "styled-components/macro";
import {useNavigate} from "react-router-dom";
import check from "../assets/check-circle.svg";

export default function ReceivedCredentials({response, setResponse}) {
    const navigate = useNavigate();

    const handleClick = (e) => {
        switch (e.target.id) {
            case "copyUrl":
                navigator.clipboard.writeText(response?.url);
                break;
            case "copyUsername":
                navigator.clipboard.writeText(response?.username);
                break;
            case "copyPassword":
                navigator.clipboard.writeText(response?.password);
                break;
            default:
                break;
        }
    }

    const handleClickBack = () => {
        setResponse(null);
        navigate('/');
    }

    return (
        <>
            <StyledHeadline>
                <img src={check} alt='check-icon' width='50px' height='50px'/>
                <p>You got it!</p>
            </StyledHeadline>
            <StyledBody>
                <p>Url:</p>
                <p>{response?.url}</p>
                <p>Username/EMail:</p>
                <p>{response?.username}</p>
                <p>Password:</p>
                <p>{response?.password}</p>
            </StyledBody>
            <StyledButtonSection>
                <StyledButton id='copyUrl' onClick={handleClick}>copy url</StyledButton>
                <StyledButton id='copyUsername' onClick={handleClick}>copy username / email</StyledButton>
                <StyledButton id='copyPassword' onClick={handleClick}>copy password</StyledButton>
            </StyledButtonSection>
            <StyledButtonSection>
                <StyledButton id='back' onClick={handleClickBack}>Back</StyledButton>
            </StyledButtonSection>
        </>
    )
}

const StyledButton = styled.button`
    background-color: #5a665b;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    color: #67d571;
`

const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin: 20px;
`

const StyledHeadline = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 30px;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

const StyledBody = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
`