import {createNewEntry} from "../services/CredentialsService";
import {useState} from "react";
import styled from "styled-components/macro";
import {useNavigate} from "react-router-dom";

export default function SetCredentials({response, setResponse}){

    const [credentials, setCredentials] = useState({
        url: null,
        username: null,
        password: null
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.id]:e.target.value});
    }

    const handleSubmit = () => {
        createNewEntry(credentials)
            .then(res => {
                if (res.status === 200) {
                    setResponse({...response, 'password': res.data.password, 'id': res.data.id});
                    setCredentials(null)
                }
            })
    }

    return(
        <StyledInsertSection>
            <StyledInsertSection>
            <StyledParagraph>URL</StyledParagraph>
            <StyledInput id="url" value={credentials?.url} onChange={handleChange}/>
            <StyledParagraph>USERNAME/E-MAIL</StyledParagraph>
            <StyledInput id="username" value={credentials?.username} onChange={handleChange}/>
            <StyledParagraph>PASSWORD</StyledParagraph>
            <StyledInput id="password" value={credentials?.password} onChange={handleChange}/>
            <StyledButton onClick={handleSubmit}>SEND</StyledButton>
            </StyledInsertSection>
                <StyledButtonSection>
                <StyledButton id='back' onClick={() => navigate('/')}>Back</StyledButton>
            </StyledButtonSection>
        </StyledInsertSection>
    )
}

const StyledInput = styled.input`
    width: 250px;
    height: 30px;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    font-size: 16px;
`

const StyledInsertSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
`
const StyledButton = styled.button`
    background-color: #5a665b;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    color: #67d571;
`

const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    margin: 20px;
`
const StyledParagraph = styled.p`
    margin: 5px 0 5px 0;
`