import styled from "styled-components/macro";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {getEntry} from "../services/CredentialsService";

export default function GetEntryByCredentials(props) {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        id: null,
        password: null
    });
    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.id]:e.target.value});
    }

    const handleSubmit = () => {
        getEntry(credentials)
            .then(res => {
                if (res.status === 200) {
                    props.setResponse({
                        ...props.response,
                        'url': res.data.url,
                        'username': res.data.username,
                        'password': res.data.password
                    });
                    setCredentials(null)
                }
            })
    }

    return (
        <StyledInsertSection>
            <StyledInsertSection>
                <StyledParagraph>ID</StyledParagraph>
                <StyledInput id="id" value={credentials?.id} onChange={handleChange}/>
                <StyledParagraph>PASSWORD</StyledParagraph>
                <StyledInput id="password" value={credentials?.password} onChange={handleChange}/>
                <StyledButton onClick={handleSubmit}>SEND</StyledButton>
            </StyledInsertSection>
            <StyledButtonSection>
                <StyledButton id='back' onClick={() => navigate('/')}>Back</StyledButton>
            </StyledButtonSection>
        </StyledInsertSection>
    )
}

const StyledInsertSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
`

const StyledButton = styled.button`
    background-color: #5a665b;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    color: #67d571;
`

const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin: 20px;
`

const StyledInput = styled.input`
    width: 250px;
    height: 30px;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    font-size: 16px;
`

const StyledParagraph = styled.p`
    margin: 5px 0 5px 0;
`