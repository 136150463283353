import React, {useEffect} from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import Home from "./pages/Home";
import GetEntry from "./pages/GetEntry";
import CreateEntry from "./pages/CreateEntry";
import PasswordGenerator from "./pages/PasswordGenerator";
import passTransferLogo from "./assets/passTransferLogo2.png";
import styled from "styled-components/macro";

function App() {

    return (
        <StyledAppContainer>
            <StyledLogoContainer>
                <StyledLogo src={passTransferLogo} alt="logo"/>
            </StyledLogoContainer>
            <Router>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="/getentry" element={<GetEntry/>}/>
                    <Route path="/createentry" element={<CreateEntry/>}/>
                    <Route path="/getentry/:id" element={<GetEntry/>}/>
                    <Route path="/passwordgenerator" element={<PasswordGenerator/>}/>
                    <Route path="*" element={<Navigate replace to="/"/>}/>
                </Routes>
            </Router>
        </StyledAppContainer>
    );
}

export default App;

const StyledLogoContainer = styled.div`
display: flex;
justify-content: center;
margin: 10px 0 20px 0;
`

const StyledLogo = styled.img`
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 180px;
`

const StyledAppContainer = styled.div`
    height: 100vh;
    max-width: 800px;
    overflow: hidden;
    margin: auto;
`

