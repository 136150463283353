import styled from "styled-components/macro";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function PasswordGenerate({passwordOptions}){

    const navigate = useNavigate();

    const [password, setPassword] = useState("")

    useEffect(() => {
        setPassword(generatePassword)
    }, [passwordOptions])

    const listOfCharsLowercase = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const listOfCharsUppercase = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const listOfNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const listOfSymbols = ['!', '"', '§', '$', '%', '&', '/', '(', ')', '=', '?', '´', '*', '+', '#', '-', '_', '.', ',', ';', ':', '°', '{', '}', '@', '€', '£', '¢', '¥', 'µ', 'ß'];

    const generatePassword = () => {
        let password = '';
        let listOfChars = [];
        for (let i = 0; i < passwordOptions?.length; i++) {
            if (passwordOptions?.uppercase) {
                listOfChars = listOfChars.concat(listOfCharsUppercase);
            }
            if (passwordOptions?.lowercase) {
                listOfChars = listOfChars.concat(listOfCharsLowercase);
            }
            if (passwordOptions?.numbers) {
                listOfChars = listOfChars.concat(listOfNumbers);
            }
            if (passwordOptions?.symbols) {
                listOfChars = listOfChars.concat(listOfSymbols);
            }
            password += listOfChars[Math.floor(Math.random() * listOfChars.length)];
        }

        return password;
    }

    const handleClick = () => {
        navigator.clipboard.writeText(password);
    }
    return (
        <StyledContainer>
            <p>Generated Password</p>
            <p>{password.includes('undefined') ? '' : password}</p>
            <StyledButtonSection>
                <StyledButton onClick={handleClick} disabled={password.includes('undefined')}>Copy</StyledButton>
                <StyledButton id='back' onClick={() => navigate('/')}>Back</StyledButton>
            </StyledButtonSection>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 20px;
    height: 100%;
`

const StyledButton = styled.button`
    background-color: #5a665b;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    color: #67d571;
`

const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    margin: 20px;
    align-items: center;
    height: 100%;
`