import {useParams} from "react-router-dom";
import GetEntryById from "../components/GetEntryById";
import GetEntryByCredentials from "../components/GetEntryByCredentials";
import {useState} from "react";
import ReceivedCredentials from "../components/ReceivedCredentials";

export default function GetEntry() {
    const { id } = useParams()
    const [response, setResponse] = useState(null);
    return (
        <>
            {response == null ?
                id !== undefined ?
                    <GetEntryById id={id} response={response} setResponse={setResponse}/>
                    :
                    <GetEntryByCredentials response={response} setResponse={setResponse}/>
                :
                <ReceivedCredentials response={response} setResponse={setResponse}/>
            }
        </>
    )
}

