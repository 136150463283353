import check from '../assets/check-circle.svg'
import styled from "styled-components/macro";
import {useNavigate} from "react-router-dom";

export default function RespondedCredentials({response, setResponse}){

    const navigate = useNavigate();

    const responseUrl = window.location.href.replace("createEntry", "getentry/" + response?.id);

    const copyUrl = 'Hey You! ☺️ Someone shared a password with you. Click on the link below and insert the password. The Url is: ' + responseUrl
    const copyAll = 'Hey You! ☺️ Someone shared a password with you. Click on the link below and insert the password. The Url is: ' + responseUrl + ' The password is: ' + response?.password;

    const handleClick = (e) => {
        switch (e.target.id) {
            case "copyUrl":
                navigator.clipboard.writeText(copyUrl);
                break;
            case "copyPassword":
                navigator.clipboard.writeText(response?.password);
                break;
            case "copyAll":
                navigator.clipboard.writeText(copyAll);
                break;
            case "newEntry":
                setResponse(null);
                break;
            default:
                break;
        }
    }

    return(
        <StyledCredentialsContainer>
            <StyledHeadline>
                <img src={check} alt='check-icon' width='50px' height='50px'/>
                <StyledParagraph>Nice one!</StyledParagraph>
            </StyledHeadline>
            <StyledBody>
                <StyledParagraph>Your link to share is:</StyledParagraph>
                <StyledParagraph>{<a href={responseUrl} target="_blank">{responseUrl}</a>}</StyledParagraph>
                <StyledParagraph>Your password to share is: </StyledParagraph>
                <StyledParagraph>{response?.password}</StyledParagraph>
            </StyledBody>
            <StyledButtonSection>
                <StyledButton id='copyUrl' onClick={handleClick}>copy url</StyledButton>
                <StyledButton id='copyPassword' onClick={handleClick}>copy password</StyledButton>
                <StyledButton id='copyAll' onClick={handleClick}>copy all</StyledButton>
            </StyledButtonSection>
            <StyledButtonSection>
            <StyledButton id='newEntry' onClick={handleClick}>Insert a new entry</StyledButton>
            </StyledButtonSection>
            <StyledButtonSection>
                <StyledButton id='back' onClick={() => navigate('/')}>Back</StyledButton>
            </StyledButtonSection>
        </StyledCredentialsContainer>
    )
}

const StyledParagraph = styled.p`
    margin: 5px 0 5px 0;
`

const StyledCredentialsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin: 15px;
`

const StyledButton = styled.button`
    background-color: #5a665b;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    color: #67d571;
`

const StyledHeadline = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 30px;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

const StyledBody = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
`

